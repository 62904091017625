import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/backoffice/login',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice',
    name: 'Login',
    component: () => import('../views/Backoffice/BackofficeLogin.vue')
  },
  {
    path: '/backoffice/home',
    name: 'Home',
    component: () => import('../views/Backoffice/BackofficeHome.vue')
  },
  {
    path: '/backoffice/logout',
    name: 'Logout',
    component: () => import('../views/Backoffice/BackofficeLogout.vue')
  },
  {
    path: '/backoffice/backoffice_users',
    name: 'BackofficeUsers',
    component: () => import('../views/Backoffice/BackofficeUsers.vue')
  },
  {
    path: '/backoffice/endusers',
    name: 'BackofficeEndusers',
    component: () => import('../views/Backoffice/BackofficeEndusers.vue')
  },
  {
    path: '/backoffice/wysiwyg',
    name: 'Wysiwyg',
    component: () => import('../views/Backoffice/BackofficeWysiwig.vue')
  },
  {
    path: '/backoffice/forgot_password',
    name: 'BackofficeForgotPassword',
    component: () => import('../views/Backoffice/BackofficeForgotPassword.vue')
  },
  {
    path : '/backoffice/backoffice_user_delete',
    name : 'BackofficeUserDelete',
    component : () => import('../views/Backoffice/BackofficeUserDelete.vue')
  },
  {
    path : '/backoffice/backoffice_user_edit',
    name : 'BackofficeUserEdit',
    component : () => import('../views/Backoffice/BackofficeUserEdit.vue')
  },
  {
    path : '/backoffice/backoffice_login_as_point',
    name : 'BackofficeLoginAsPoint',
    component : () => import('../views/Backoffice/BackofficeLoginAsPoint.vue')
  },
  {
    path : '/backoffice/unit_delete_alert',
    name : 'BackofficeUnitDeleteWarning',
    component : () => import('../views/Backoffice/BackofficeUnitDeleteWarning.vue')
  },
  {
    path : '/backoffice/unit_delete',
    name : 'BackofficeUnitDelete',
    component : () => import('../views/Backoffice/BackofficeUnitDelete.vue')
  },
  {
    path : '/backoffice/users_point',
    name : 'BackofficeUsersPoint',
    component : () => import('../views/Backoffice/BackofficeUsersPoint.vue')
  },
  {
    path : '/backoffice/backoffice_user_change_password',
    name : 'BackofficeUserChangePassword',
    component : () => import('../views/Backoffice/BackofficeUserChangePassword.vue')
  },
  {
    path : '/backoffice/file_uploader',
    name : 'BackofficeFileUploader',
    component : () => import('../views/Backoffice/BackofficeFileUploader.vue')
  },
  {
    path : '/backoffice/cloudinary_file_uploader',
    name : 'BackofficeCloudinaryFileUploader',
    component : () => import('../views/Backoffice/BackofficeCloudinaryFileUploader.vue')
  },
  {
    path : '/backoffice/s3_file_uploader',
    name : 'BackofficeS3FileUploader',
    component : () => import('../views/Backoffice/BackofficeS3FileUploader.vue')
  },
  {
    path : '/backoffice/distribution_points',
    name : 'BackofficeDistributionPoints',
    component : () => import('../views/Backoffice/BackofficeDistributionPoints.vue')
  },
  {
    path : '/backoffice/distribution_point',
    name : 'BackofficeDistributionPoint',
    component : () => import('../views/Backoffice/BackofficeDistributionPoint.vue')
  },
  {
    path : '/backoffice/point_details',
    name : 'BackofficePointDetails',
    component : () => import('../views/Backoffice/BackofficePointDetails.vue')
  },
  {
    path : '/backoffice/point_admin',
    name : 'BackofficePointAdmin',
    component : () => import('../views/Backoffice/BackofficePointAdmin.vue')
  },
  {
    path : '/backoffice/point_management',
    name : 'BackofficePointManagement',
    component : () => import('../views/Backoffice/BackofficePointManagement.vue')
  },
  {
    path : '/backoffice/edit_units',
    name : 'BackofficeEditUnits',
    component : () => import('../views/Backoffice/BackofficeEditUnits.vue')
  },
  {
    path : '/backoffice/edit_boxes',
    name : 'BackofficeEditBoxes',
    component : () => import('../views/Backoffice/BackofficeEditBoxes.vue')
  },
  {
    path : '/backoffice/add_box',
    name : 'BackofficeAddBox',
    component : () => import('../views/Backoffice/BackofficeAddBox.vue')
  },
  {
    path : '/backoffice/edit_box_size',
    name : 'BackofficeEditBoxSize',
    component : () => import('../views/Backoffice/BackofficeEditBoxSize.vue')
  },
  {
    path : '/backoffice/viewing_endusers_admin',
    name : 'BackofficeViewingEndusersAdmin',
    component : () => import('../views/Backoffice/BackofficeViewingEndusersAdmin.vue')
  },
  {
    path : '/backoffice/backoffice_user_packages_admin',
    name : 'BackofficeUserPackagesAdmin',
    component : () => import('../views/Backoffice/BackofficeUserPackagesAdmin.vue')
  },
  {
    path : '/backoffice/backoffice_add_enduser',
    name : 'BackofficeAddEnduser',
    component : () => import('../views/Backoffice/BackofficeAddEnduser.vue')
  },
  {
    path : '/backoffice/backoffice_admin_get_distribution_point_statistics',
    name : 'BackofficeAdminGetDistributionPointStatistics',
    component : () => import('../views/Backoffice/BackofficeAdminGetDistributionPointStatistics.vue')
  },
  {
    path : '/backoffice/statistics',
    name : 'BackofficeStatistics',
    component : () => import('../views/Backoffice/BackofficeStatistics.vue')
  },
  {
    path : '/backoffice/backoffice_user_packages',
    name : 'BackofficeUserPackages',
    component : () => import('../views/Backoffice/BackofficeUserPackages.vue')
  },
  {
    path : '/backoffice/backoffice_casuals_couriers',
    name : 'BackofficeCasualsCouriers',
    component : () => import('../views/Backoffice/BackofficeCasualsCouriers.vue')
  },
  // /backoffice/casuals_couriers_details
  {
    path : '/backoffice/casuals_couriers_details',
    name : 'BackofficeCasualsCouriersDetails',
    component : () => import('../views/Backoffice/BackofficeCasualsCouriersDetails.vue')
  },
  {
    path: '/point',
    name: 'Point',
    component: () => import('../views/Point/Point.vue')
  },
  {
    path: '/point/point_home',
    name: 'PointHome',
    component: () => import('../views/Point/PointHome.vue')
  },
  {
    path: '/point/point_logout',
    name: 'PointLogout',
    component: () => import('../views/Point/PointLogout.vue')
  },
  {
    path: '/point/point_logout',
    name: 'PointLogout',
    component: () => import('../views/Point/PointLogout.vue')
  },
  {
    path: '/point/point_customer',
    name: 'PointCustomer',
    component: () => import('../views/Point/PointCustomer.vue')
  },
  {
    path: '/point/point_delete_customr',
    name: 'PointDeleteCustomer',
    component: () => import('../views/Point/PointDeleteCustomer.vue')
  },
  {
    path: '/point/point_add_customer',
    name: 'PointAddCustomer',
    component: () => import('../views/Point/PointAddCustomer.vue')
  },
  {
    path: '/point/point_packages',
    name: 'PointPackages',
    component: () => import('../views/Point/PointPackages.vue')
  },
  {
    path: '/point/point_new_package',
    name: 'PointNewPackage',
    component: () => import('../views/Point/PointNewPackage.vue')
  },
  {
    path: '/point/point_package_validation',
    name: 'PointPackageValidation',
    component: () => import('../views/Point/PointPackageValidation.vue')
  },
  {
    path : '/point/point_base',
    name : 'PointBase',
    component : () => import('../views/Point/PointBase.vue')
  },
  {
    path : '/backoffice/point_delete',
    name : 'PointDelete',
    component : () => import('../views/Backoffice/PointDelete.vue')
  },
  {
    path : '/backoffice/distribution_point_edit',
    name : 'DistributionPointEdit',
    component : () => import('../views/Backoffice/DistributionPointEdit.vue')
  },
  {
    path : '/backoffice/backoffice_delete_enduser',
    name : 'BackofficeDeleteEnduser',
    component : () => import('../views/Backoffice/BackofficeDeleteEnduser.vue')
  },


  // Start changes
  {
    path : '/backoffice/courier_companies',
    name : 'BackofficeCourierCompanies',
    component : () => import('../views/Backoffice/BackofficeCourierCompanies.vue')
  },
  {
    path : '/backoffice/courier_company',
    name : 'BackofficeCourierCompany',
    component : () => import('../views/Backoffice/BackofficeCourierCompany.vue')
  },
  {
    path : '/backoffice/courier_company_details',
    name : 'BackofficeCourierCompanyDetails',
    component : () => import('../views/Backoffice/BackofficeCourierCompanyDetails.vue')
  },
  {
    path : '/backoffice/courier_company_edit',
    name : 'CourierCompanyEdit',
    component : () => import('../views/Backoffice/CourierCompanyEdit.vue')
  },
  {
    path : '/backoffice/couriers',
    name : 'BackofficeCouriers',
    component : () => import('../views/Backoffice/BackofficeCouriers.vue')
  },
  {
    path : '/backoffice/backoffice_courier_edit',
    name : 'BackofficeCourierEdit',
    component : () => import('../views/Backoffice/BackofficeCourierEdit.vue')
  },
  {
    path: '/backoffice/BackofficeCourierCompanyChangePassword',
    name : 'BackofficeCourierCompanyChangePassword',
    component : () => import('../views/Backoffice/BackofficeCourierCompanyChangePassword.vue')
  },

/*
  {
    path : '/backoffice/point_admin',
    name : 'BackofficePointAdmin',
    component : () => import('../views/Backoffice/BackofficePointAdmin.vue')
  },
  {
    path : '/backoffice/point_management',
    name : 'BackofficePointManagement',
    component : () => import('../views/Backoffice/BackofficePointManagement.vue')
  },*/
  
  
  {
    path: '/courier',
    name: 'Courier',
    component: () => import('../views/Courier/Courier.vue')
  },
  {
    path: '/courier/courier_home',
    name: 'CourierHome',
    component: () => import('../views/Courier/CourierHome.vue')
  },
  {
    path: '/courier/courier_logout',
    name: 'CourierLogout',
    component: () => import('../views/Courier/CourierLogout.vue')
  },

  {//Building (point) courier deposit - no login
    path: '/courier/courier_point_new_package_e',
    name: 'CourierPointNewPackageE',
    component: () => import('../views/Courier/CourierPointNewPackageE.vue')
  },
  {
    path: '/courier/courier_point_new_package_validation_e',
    name: 'CourierPointNewPackagesValidationE',
    component: () => import('../views/Courier/CourierPointNewPackageValidationE.vue')
  },
 {//Building/point enduser package pickup - no login
  path: '/courier/enduser_point_package_pickup',
  name: 'EndUserPointPackagePickup',
  component: () => import('../views/Courier/EndUserPointPackagePickup.vue')
},
 
  {
    path: '/couriercompany',
    name: 'CourierCompany',
    component: () => import('../views/CourierCompany/CourierCompany.vue')
  },
  {
    path: '/couriercompany/courier_company_home',
    name: 'CourierCompanyHome',
    component: () => import('../views/CourierCompany/CourierCompanyHome.vue')
  },
  {
    path: '/couriercompany/courier_company_logout',
    name: 'CourierCompanyLogout',
    component: () => import('../views/CourierCompany/CourierCompanyLogout.vue')
  },
  {
    path: '/couriercompany/courier_company_courier',
    name: 'CourierCompanyCourier',
    component: () => import('../views/CourierCompany/CourierCompanyCourier.vue')
  },
  {
    path: '/couriercompany/courier_company_add_courier',
    name: 'CourierCompanyAddCourier',
    component: () => import('../views/CourierCompany/CourierCompanyAddCourier.vue')
  },
  {
    path: '/couriercompany/courier_company_delete_courier',
    name: 'CourierCompanyDeleteCourier',
    component: () => import('../views/CourierCompany/CourierCompanyDeleteCourier.vue')
  },
  // CasualCouriers
  {
    // CasualCouriersLogin
    path: '/casual_couriers/casual_couriers_login',
    name: 'CasualCouriersLogin',
    component: () => import('../views/CasualsCouriers/CasualCouriersLogin.vue')
  },
  {
    path: '/casual_couriers/casual_couriers_add_package',
    name: 'CasualCouriersAddPackage',
    component: () => import('../views/CasualsCouriers/CasualCouriersAddPackage.vue')
  },
  {
    path: '/casual_couriers/sms_step',
    name: 'CasualCouriersSmsStep',
    component: () => import('../views/CasualsCouriers/CasualCouriersSmsStep.vue')
  },
  {
    path: '/casual_couriers/package_validation',
    name: 'CasualCouriersPackageValidation',
    component: () => import('../views/CasualsCouriers/CasualCouriersPackageValidation.vue')
  },
  {
    path: '/casual_couriers/thanks',
    name: 'CasualCouriersThanks',
    component: () => import('../views/CasualsCouriers/CasualCouriersThanks.vue')
  },

  {
    path: '/',
    component : () => import('../views/Hp.vue')
  },
  {
    path: '/support',
    component : () => import('../views/Support.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component : () => import('../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
